 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="用户组名称">
				<el-input class="el_input" v-model="form.name" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="success" @click="open_add_view">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column width="100" label="ID" prop="id"></el-table-column>
				<el-table-column label="名称" prop="name"></el-table-column>
				
				<!-- 操作行 -->
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<el-button v-if="scope.row.could_edit==1&&scope.row.could_del==1" @click="open_edit_view(scope.row)" size="mini" type="text" icon="el-icon-edit">修改</el-button>
						<el-button v-if="scope.row.could_edit==1&&scope.row.could_del==1" @click="del(scope.row.id)" size="mini" type="text" icon="el-icon-circle-close">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 用户数据修改弹窗 -->
		<el-dialog top="3vh" title="修改用户组" width="60%" :visible.sync="edit_para.is_show">
			<el-form status-icon label-position="left" label-width="90px">
				<el-form-item label="用户组名称" >
					<el-input class="el_input" v-model="edit_para.form.name" ></el-input>
				</el-form-item>
				<el-form-item label="用户组权限" >
					<div class="menu_list">
						<div v-for="(main_item,index) in menu_list" :key="index">
							<div>{{main_item.name}}</div>
							<el-checkbox-group class="children_list" v-model="edit_para.form.menu">
								<el-checkbox v-for="(children_item,index) in main_item.children" :key="index" :label="children_item.id">{{children_item.name}}</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
				</el-form-item>
			</el-form>
			<div style="text-align:center">
				<el-button type="primary" @click="edit_view_sub">提交</el-button>
			</div>
		</el-dialog>

		<!-- 用户组添加弹窗 -->
		<el-dialog top="3vh" title="添加用户组" width="60%" :visible.sync="add_para.is_show">
			<el-form status-icon label-position="left" label-width="90px">
				<el-form-item label="用户组名称" >
					<el-input class="el_input" v-model="add_para.form.name" ></el-input>
				</el-form-item>
				<el-form-item label="用户组权限" >
					<div class="menu_list">
						<div v-for="(main_item,index) in menu_list" :key="index">
							<div>{{main_item.name}}</div>
							<el-checkbox-group class="children_list" v-model="add_para.form.menu">
								<el-checkbox v-for="(children_item,index) in main_item.children" :key="index" :label="children_item.id">{{children_item.name}}</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
				</el-form-item>
			</el-form>
			<div style="text-align:center">
				<el-button type="primary" @click="add_view_sub">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {

				//菜单列表
				menu_list:{},
				menu_list_loaded:false,//是否加载过

				//搜索条件
				form: {				
					name:'',//姓名
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
				},

				//用户修改弹窗
				edit_para:{
					form:{},
					is_show:false,
				},

				//用户添加弹窗
				add_para:{
					form:{
						name:'',
						menu:[],
					},
					is_show:false,
				}
			}
		},
		created() {
			
			//读取用户组数据
			this.get_page_data()
		},
		methods: {

			//删除用户组
			del(ug_id){

				//询问
				this.$my.other.confirm({
					content:"是否删除此用户组",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'admin',
								ctr:'ug_del_by_admin',
								id:ug_id
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							},
						});
					}
				});
			},

			//创建用户组
			add_view_sub(){
				
				this.$my.other.confirm({
					content:"是否创建此用户组",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'admin',
								ctr:'ug_add_by_admin',
								...this.add_para.form
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.add_para.is_show=false;
								this.get_page_data()
							},
						});
					}
				});
			},

			//打开用户添加页面
			open_add_view(){
				this.get_menu_list()
				this.add_para.is_show=true
			},

			//提交修改
			edit_view_sub(){
				
				//提交
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'ug_edit_by_admin',
						id:this.edit_para.form.id,
						name:this.edit_para.form.name,
						menu:this.edit_para.form.menu,
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});
						this.edit_para.is_show=false;
						this.get_page_data()
					},
				});
			},

			//打开用户组修改页面
			open_edit_view(item){
				this.get_menu_list()
				this.edit_para.form=item
				this.edit_para.is_show=true
			},
			
			//搜索
			page_ser(){
	
				//读取
				this.get_page_data()
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'ug_list_by_admin',
						p:1,
						num:1000
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//预处理
						for(let item of data.list){

							//额外权限
							item.menu=item.menu.split(',')
							for(var i in item.menu){
								if(!item.menu[i].trim())item.menu.splice(i,1)
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},

			//读取菜单列表
			get_menu_list(){

				//读取过
				if(this.menu_list_loaded){
					return
				}

				//去服务器读取
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'menu_list_by_admin',
						p:1,
						num:1000
					},
					callback:(data)=>{
						console.log(data,777)
						//格式化主菜单
						let menu_buf={}
						let menu_main=[];
						let menu_children={};
						for(var item of data.list){

							menu_buf[item.id]=item;

							if(item.fid==0){
								menu_main.push(item)
							}else{
								if(!menu_children[item.fid])menu_children[item.fid]=[];
								menu_children[item.fid].push(item)
							}
						}
						
						//格式化子菜单
						for(var i in menu_main){
							
							menu_main[i].children=menu_children[menu_main[i].id]
						}
						
						//置入
						this.menu_list=menu_main
						console.log(this.menu_list)
						this.menu_list_loaded=true
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		width: 90%;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 25%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>